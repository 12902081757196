<template>
  <div class="footer">
    <div class="footer__desktop">
      <div class="footer__desktop__links">
        <nuxt-link
          class="footer__desktop__links__link"
          :to="localePath('TermsAndConditions')"
        >
          {{ $t('links.terms-and-conditions') }}
        </nuxt-link>
        <nuxt-link
          class="footer__desktop__links__link"
          :to="localePath('PrivacyPolicy')"
        >
          {{ $t('links.privacy-policy') }}
        </nuxt-link>
        <a
          class="footer__desktop__links__link"
          href="javascript:void(0)"
          @click="openAxeptioCookies"
        >
          {{ $t('links.cookies-settings') }}
        </a>
        <nuxt-link
          class="footer__desktop__links__link"
          :to="localePath('Impressum')"
        >
          {{ $t('links.impressum') }}
        </nuxt-link>
        <!--        <a href="#" target="_blank" class="footer__desktop__links__link">-->
        <!--          {{ $t('links.blog') }}-->
        <!--        </a>-->
<!--        <a-->
<!--          :href="$t('common.support.home')"-->
<!--          target="_blank"-->
<!--          rel="noreferer noopener"-->
<!--          class="footer__desktop__links__link"-->
<!--        >-->
<!--          {{ $t('links.help-center') }}-->
<!--        </a>-->
      </div>
      <a
        :href="$t('common.lemonway.terms')"
        rel="noreferer noopener"
        target="_blank"
      >
        <img
          class="footer__desktop__lemonway"
          src="./img/lemonway.svg"
          alt="Logo Lemonway"
        />
      </a>
      <div class="footer__desktop__socials">
        <a :href="$t('common.social-links.instagram')" target="_blank">
          <img src="./img/instagram.svg" alt="Instagram icon" />
        </a>
        <a :href="$t('common.social-links.tiktok')" target="_blank">
          <img src="./img/tiktok.svg" alt="Tiktok icon" />
        </a>
        <a :href="$t('common.social-links.facebook')" target="_blank">
          <img src="./img/facebook.svg" alt="Facebook icon" />
        </a>
        <a :href="$t('common.social-links.youtube')" target="_blank">
          <img src="./img/youtube.svg" alt="Youtube icon" />
        </a>
      </div>
    </div>
    <transition name="fade">
      <div
        v-if="mobileBanner"
        class="footer__mobile"
        @click="$bus.$emit('menu:hide')"
      >
        <NuxtLink
          :to="localePath({ name: 'AddProduct' })"
          class="footer__mobile__sell"
        >
          <PlusSvg width="44" height="44" />
        </NuxtLink>
        <div v-if="$auth.loggedIn" class="footer__mobile__icons">
          <div class="footer__mobile__icons__group">
            <nuxt-link
              v-if="$auth.loggedIn"
              :to="localePath('Account-Inbox')"
              class="footer__mobile__icons__icon"
            >
              <LetterSvg width="22" height="22" />
              <client-only placeholder="">
                <span
                  v-if="unreadConversationCount"
                  class="footer__mobile__icons__icon__badge"
                >
                  {{ unreadConversationCount }}
                </span>
              </client-only>
            </nuxt-link>
            <div
              class="footer__mobile__icons__icon"
              @click="$refs.notificationsModal?.show()"
            >
              <NotificationBellSvg />
              <client-only placeholder="">
                <span
                  v-if="unreadNotificationCount"
                  class="footer__mobile__icons__icon__badge"
                >
                  {{ unreadNotificationCount }}
                </span>
              </client-only>
            </div>
          </div>
          <div class="footer__mobile__icons__group">
            <nuxt-link
              v-if="$auth.loggedIn"
              :to="localePath('ListPage-UserFavorites')"
              class="footer__mobile__icons__icon"
            >
              <HeartSvg width="22" height="22" />
            </nuxt-link>
            <a
              :href="$t('common.support.home')"
              target="_blank"
              rel="noreferer noopener"
              class="footer__mobile__icons__icon"
            >
              <QuestionSvg />
            </a>
          </div>
        </div>
        <div v-else class="footer__mobile__icons">
          <div class="footer__mobile__icons__group">
            <div
              class="footer__mobile__icons__icon"
              @click="$bus.$emit('auth:login:show')"
            >
              <ProfileSvg />
            </div>
          </div>
          <div class="footer__mobile__icons__group">
            <a
              :href="$t('common.support.home')"
              target="_blank"
              rel="noreferer noopener"
              class="footer__mobile__icons__icon"
            >
              <QuestionSvg />
            </a>
          </div>
        </div>
      </div>
    </transition>
    <NotificationsModal
      ref="notificationsModal"
      @notifications:reload="$emit('notifications:reload')"
    />
  </div>
</template>

<script>
import NotificationsModal from '@/components/Modal/NotificationsModal';
import NotificationBellSvg from './img/notification-bell.svg?inline';
import PlusSvg from '~/assets/img/plus.svg?inline';
import ProfileSvg from './img/profile.svg?inline';
import QuestionSvg from './img/question.svg?inline';
import HeartSvg from './img/heart.svg?inline';
import LetterSvg from '~/assets/img/letter.svg?inline';
import { mapState } from 'pinia';
import { useInboxStore } from '@/stores/inbox';
import { useUserStore } from '@/stores/user';
import isNil from 'lodash.isnil';

export default {
  name: 'Footer',
  components: {
    NotificationsModal,
    NotificationBellSvg,
    ProfileSvg,
    QuestionSvg,
    HeartSvg,
    LetterSvg,
    PlusSvg
  },
  data: () => ({
    mobileBanner: true
  }),
  computed: {
    ...mapState(useInboxStore, ['unreadConversations']),
    ...mapState(useUserStore, ['unreadNotifications']),
    unreadConversationCount() {
      if (isNil(this.unreadConversations)) return 0;
      return this.unreadConversations(this.$auth.user.id).length;
    },
    unreadNotificationCount() {
      if (isNil(this.unreadNotifications)) return 0;
      return this.unreadNotifications.length;
    }
  },
  mounted() {
    this.$bus.$on('footer:mobile-banner:show', this.showMobileBanner);
    this.$bus.$on('footer:mobile-banner:hide', this.hideMobileBanner);
  },
  beforeDestroy() {
    this.$bus.$off('footer:mobile-banner:show', this.showMobileBanner);
    this.$bus.$off('footer:mobile-banner:hide', this.hideMobileBanner);
  },
  methods: {
    openAxeptioCookies() {
      if (typeof window.openAxeptioCookies !== 'function') return;
      window.openAxeptioCookies();
    },
    showMobileBanner() {
      this.mobileBanner = true;
    },
    hideMobileBanner() {
      this.mobileBanner = false;
    }
  }
};
</script>

<style lang="scss" scoped src="./footer.scss"></style>
