<template>
  <div
    v-if="entries"
    v-scroll-lock="show"
    class="menu"
    :class="{ 'menu--shown': show }"
  >
    <Button
      :to="localePath({ name: 'AddProduct' })"
      size="large"
      class="menu__button"
      @click="$emit('menu:close')"
    >
      {{ $t('common.sell-now') }}
    </Button>
    <Button
      v-if="!$auth.loggedIn"
      size="large"
      outline
      class="menu__button"
      @click="$bus.$emit('auth:login:show')"
    >
      {{ $t('header.signin-signup') }}
    </Button>
    <ul>
      <li class="menu__entry">
        <a
          href="javascript:void(0)"
          class="menu__entry__link"
          @click="showLanguages = !showLanguages"
        >
          <div class="menu__entry__label">
            {{ $t('header.language', { fullName: currentLocale.fullname }) }}
          </div>
          <ArrowRight
            class="menu__entry__arrow menu__entry__arrow--down"
            :class="{ 'menu__entry__arrow--up': showLanguages }"
          />
        </a>
        <ul v-if="showLanguages">
          <li
            v-for="loc in $i18n.locales"
            :key="loc.code"
            class="menu__entry__list"
            @click="switchLocale(loc)"
          >
            {{ loc.fullname }}
          </li>
        </ul>
      </li>
      <li
        v-for="entry in entries"
        :key="entry.id"
        :class="{
          menu__entry: true,
          'menu__entry--opened': opened === entry.id
        }"
      >
        <nuxt-link
          v-slot="{ href, navigate }"
          :to="categoryPath(entry.slug)"
          custom
          class="menu__entry__link"
        >
          <a
            :href="href"
            role="link"
            @click="onNavigate(navigate, entry, $event)"
            @keypress.enter="onNavigate(navigate, entry, $event)"
          >
            <div class="menu__entry__label">
              {{ entry.name }}
            </div>
            <ArrowRight v-if="entry.children" class="menu__entry__arrow" />
          </a>
        </nuxt-link>
        <transition name="right-to-left-paging" mode="in-out">
          <ul v-if="isOpened(entry)" class="menu__sub-menu">
            <li class="menu__entry">
              <div
                class="menu__entry__link menu__entry__link--return"
                @click="goBack"
              >
                <ArrowLeft class="menu__entry__arrow" />
                <span class="menu__entry__label">
                  {{ opened.name }}
                </span>
              </div>
            </li>
            <li class="menu__entry">
              <nuxt-link
                :to="categoryPath(entry.slug)"
                class="menu__entry__link menu__entry__link--return"
              >
                <div class="menu__entry__link__picto-wrapper">
                  <Dots class="menu__entry__show-full-category" />
                </div>
                <span
                  class="menu__entry__label"
                  v-html="
                    $t('header.menu.show-all-category', { name: opened.name })
                  "
                />
              </nuxt-link>
            </li>
            <li
              v-for="subEntry in entry.children"
              :key="subEntry.id"
              class="menu__entry menu__sub-entry"
            >
              <nuxt-link
                v-slot="{ href, navigate }"
                :to="categoryPath(subEntry.slug)"
                custom
                class="menu__entry__link"
              >
                <a
                  :href="href"
                  role="link"
                  @click="onNavigate(navigate, subEntry, $event)"
                  @keypress.enter="onNavigate(navigate, subEntry, $event)"
                >
                  <div class="menu__entry__link__picto-and-name">
                    <div class="menu__entry__link__picto-wrapper">
                      <CategoryIcon
                        v-if="subEntry.picto"
                        :icon="subEntry.picto"
                        width="24"
                        height="24"
                        fill="#F4A650"
                      />
                    </div>
                    <span class="menu__entry__label">
                      {{ subEntry.name }}
                    </span>
                  </div>
                  <ArrowRight
                    v-if="subEntry.children"
                    class="menu__entry__arrow"
                  />
                </a>
              </nuxt-link>
              <transition name="right-to-left-paging" mode="in-out">
                <ul v-if="isOpened(subEntry)" class="menu__sub-menu">
                  <li class="menu__entry">
                    <div
                      class="menu__entry__link menu__entry__link--return"
                      @click="goBack"
                    >
                      <ArrowLeft class="menu__entry__arrow" />
                      {{ opened.name }}
                    </div>
                  </li>
                  <li class="menu__entry">
                    <nuxt-link
                      :to="categoryPath(subEntry.slug)"
                      class="menu__entry__link menu__entry__link--return"
                    >
                      <div class="menu__entry__link__picto-wrapper">
                        <Dots class="menu__entry__show-full-category" />
                      </div>
                      <span
                        class="menu__entry__label"
                        v-html="
                          $t('header.menu.show-all-category', {
                            name: opened.name
                          })
                        "
                      />
                    </nuxt-link>
                  </li>
                  <li
                    v-for="subsubEntry in subEntry.children"
                    :key="subsubEntry.id"
                    class="menu__entry menu__sub-entry"
                  >
                    <nuxt-link
                      :to="categoryPath(subsubEntry.slug)"
                      class="menu__entry__link"
                    >
                      <span class="menu__entry__label">
                        {{ subsubEntry.name }}
                      </span>
                    </nuxt-link>
                  </li>
                </ul>
              </transition>
            </li>
          </ul>
        </transition>
      </li>

      <template v-if="$auth.loggedIn">
        <li class="menu__entry menu__entry__separator" />
        <!-- Mon dressing -->
        <li class="menu__entry">
          <nuxt-link
            :to="
              localePath({
                name: 'Member',
                params: { slug: $auth.user.slug }
              })
            "
            class="menu__entry__link"
          >
            {{ $t('header.user-dropdown.dressing') }}
          </nuxt-link>
        </li>
        <!-- Mes achats -->
        <li class="menu__entry">
          <nuxt-link
            :to="
              localePath({
                name: 'Account-UserSettings',
                query: { tab: 'orders' }
              })
            "
            class="menu__entry__link"
          >
            {{ $t('header.user-dropdown.purchases') }}
          </nuxt-link>
        </li>
        <!-- Mes ventes -->
        <li class="menu__entry">
          <nuxt-link
            :to="
              localePath({
                name: 'Account-UserSettings',
                query: { tab: 'sales' }
              })
            "
            class="menu__entry__link"
          >
            {{ $t('header.user-dropdown.sales') }}
          </nuxt-link>
        </li>
        <!-- Mon porte-monnaie -->
        <li class="menu__entry">
          <nuxt-link
            :to="
              localePath({
                name: 'Account-UserSettings',
                query: { tab: 'wallet' }
              })
            "
            class="menu__entry__link"
            v-html="
              $t('header.user-dropdown.wallet', {
                currency: $options.filters.toCurrency(balance)
              })
            "
          />
        </li>
        <!-- Mes réductions -->
        <li v-if="bundleDiscountsActive" class="menu__entry">
          <nuxt-link
            :to="
              localePath({
                name: 'Account-UserSettings',
                query: { tab: 'bundle' }
              })
            "
            class="menu__entry__link"
          >
            {{ $t('account.menu.discount') }}
          </nuxt-link>
        </li>
        <!-- Mes personnalisations -->
        <li v-if="customizationActive" class="menu__entry">
          <nuxt-link
            :to="
              localePath({
                name: 'Account-UserSettings',
                query: { tab: 'customization' }
              })
            "
            class="menu__entry__link"
          >
            {{ $t('header.user-dropdown.cutomizing') }}
          </nuxt-link>
        </li>
        <!-- Mes notifications -->
        <li class="menu__entry">
          <nuxt-link
            :to="
              localePath({
                name: 'Account-UserSettings',
                query: { tab: 'notifications' }
              })
            "
            class="menu__entry__link"
            v-html="$t('header.user-dropdown.notifications')"
          />
        </li>
        <!-- Mes coordonnées bancaires -->
        <li class="menu__entry">
          <nuxt-link
            :to="
              localePath({
                name: 'Account-UserSettings',
                query: { tab: 'bank' }
              })
            "
            class="menu__entry__link"
            v-html="$t('header.user-dropdown.bank-settings')"
          />
        </li>
        <!-- Mes adresses -->
        <li class="menu__entry">
          <nuxt-link
            :to="
              localePath({
                name: 'Account-UserSettings',
                query: { tab: 'address' }
              })
            "
            class="menu__entry__link"
            v-html="$t('header.user-dropdown.addresses')"
          />
        </li>
        <!-- Mode vacances -->
        <li class="menu__entry">
          <nuxt-link
            :to="
              localePath({
                name: 'Account-UserSettings',
                query: { tab: 'holidays' }
              })
            "
            class="menu__entry__link"
            v-html="$t('header.user-dropdown.holiday')"
          />
        </li>
        <!-- Mes paramètres -->
        <li class="menu__entry">
          <nuxt-link
            :to="
              localePath({
                name: 'Account-UserSettings',
                query: { tab: 'settings' }
              })
            "
            class="menu__entry__link"
          >
            {{ $t('header.user-dropdown.settings') }}
          </nuxt-link>
        </li>
        <li class="menu__entry">
          <a
            href="javascript:void(0)"
            class="menu__entry__link"
            @click="onLogout"
          >
            {{ $t('header.user-dropdown.logout') }}
          </a>
        </li>
      </template>

      <li class="menu__entry menu__entry__separator" />
      <!-- <li class="menu__entry">
        <nuxt-link :to="localePath('HowItWorks')" class="menu__entry__link">
          {{ $t('header.menu.how-it-works') }}
        </nuxt-link>
      </li> -->
      <li class="menu__entry">
        <span
          class="menu__entry__link menu__entry__link--red"
          @click="onReportBug"
        >
          {{ $t('header.menu.report-bug') }}
        </span>
      </li>
<!--      <li class="menu__entry">-->
<!--        <a-->
<!--          :href="$t('common.support.home')"-->
<!--          target="_blank"-->
<!--          rel="noreferer noopener"-->
<!--          class="menu__entry__link"-->
<!--        >-->
<!--          {{ $t('links.help-center') }}-->
<!--        </a>-->
<!--      </li>-->
      <li class="menu__entry menu__entry__separator" />
      <li class="menu__entry">
        <nuxt-link
          :to="localePath('TermsAndConditions')"
          class="menu__entry__link"
        >
          {{ $t('links.terms-and-conditions') }}
        </nuxt-link>
      </li>
      <li class="menu__entry">
        <nuxt-link :to="localePath('PrivacyPolicy')" class="menu__entry__link">
          {{ $t('links.privacy-policy') }}
        </nuxt-link>
      </li>
      <li class="menu__entry">
        <a
          class="menu__entry__link"
          href="javascript:void(0)"
          @click="openAxeptioCookies"
        >
          {{ $t('links.cookies-settings') }}
        </a>
      </li>
      <li class="menu__entry">
        <nuxt-link :to="localePath('Impressum')" class="menu__entry__link">
          {{ $t('links.impressum') }}
        </nuxt-link>
      </li>
    </ul>
    <div class="menu__footer">
      <a :href="$t('common.social-links.instagram')" target="_blank">
        <img src="./img/instagram.svg" alt="Instagram icon" />
      </a>
      <a :href="$t('common.social-links.tiktok')" target="_blank">
        <img src="./img/tiktok.svg" alt="Tiktok icon" />
      </a>
      <a :href="$t('common.social-links.facebook')" target="_blank">
        <img src="./img/facebook.svg" alt="Facebook icon" />
      </a>
      <a :href="$t('common.social-links.youtube')" target="_blank">
        <img src="./img/youtube.svg" alt="Youtube icon" />
      </a>
    </div>
  </div>
</template>

<script>
import CategoryIcon from '@/components/CategoryIcon/CategoryIcon';
import ArrowRight from '~/assets/img/right.svg?inline';
import ArrowLeft from '~/assets/img/left.svg?inline';
import Dots from '~/assets/img/dots.svg?inline';
import isNil from 'lodash.isnil';
import Button from '@/components/Button/Button';
import { captureErrorMixin } from '@/mixins/captureError';
import { useGlobalStore } from '@/stores/global';
import { sleep } from '@/misc/utils';
import { updateUserPreferences } from '@/api/user';
import { mapActions } from 'pinia';

export default {
  name: 'MenuMobile',
  components: {
    CategoryIcon,
    ArrowRight,
    ArrowLeft,
    Dots,
    Button
  },
  mixins: [captureErrorMixin],
  props: {
    entries: {
      type: Array,
      default: () => null
    },
    show: {
      type: Boolean,
      default: false
    },
    customizationEnabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    opened: null,
    parents: [],
    showLanguages: false,
    customizationActive: false,
    bundleDiscountsActive: false
  }),
  computed: {
    currentLocale() {
      return this.$i18n.locales.find((l) => l.code === this.$i18n.locale);
    },
    balance() {
      return this.$auth.user?.balance || 0;
    }
  },
  async created() {
    this.customizationActive = await this.$feature.isActive('customization');
    this.bundleDiscountsActive = await this.$feature.isActive(
      'bundle-discounts'
    );
  },
  methods: {
    ...mapActions(useGlobalStore, ['setGlobalLoading']),
    categoryPath(slug) {
      return this.localePath({
        name: 'Category',
        params: { slug }
      });
    },
    onTopLevelClick(entry) {
      if (entry.id !== this.opened.id) {
        this.opened = entry;
      } else {
        this.opened = null;
      }
    },
    onNavigate(navigate, entry, event) {
      event.preventDefault();
      if (entry.children?.length) {
        this.parents.unshift(this.opened);
        this.opened = entry;
      }
    },
    isOpened(entry) {
      if (isNil(this.opened)) return false;
      if (this.opened.id === entry.id) return true;
      return !isNil(this.parents.find((e) => e && e.id === entry.id));
    },
    goBack() {
      this.opened = this.parents.shift() || null;
    },
    openAxeptioCookies() {
      if (typeof window.openAxeptioCookies !== 'function') return;
      window.openAxeptioCookies();
    },
    async switchLocale(locale) {
      this.setGlobalLoading(true);
      const { code } = locale;
      !isNil(code) && this.$i18n.setLocale(code);
      this.showLanguages = false;
      if (this.$auth.loggedIn) {
        await this.try(async () => {
          await this.$api.doRequest(updateUserPreferences, this.$auth.user.id, {
            locale: code
          });
          await this.$auth.fetchUser();
        });
      } else {
        await sleep(150);
        window.location.reload();
      }
      // Give the time to page to reload
      await sleep(10000);
      this.setGlobalLoading(false);
    },
    async onLogout() {
      await this.$auth.logout();
    },
    onReportBug() {
      this.$emit('menu:close');
      this.$markerIo?.displayForm();
    }
  }
};
</script>

<style lang="scss" scoped src="./menu-mobile.scss"></style>
